import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.texteditor-container': { flex: 1, width: '40%' },
    '.preview': { display: { xs: 'none', lg: 'flex' } },
  },
});

export default useSx;
