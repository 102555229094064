import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Title from '@components/Title';
import useSx from './sx';

const Header = ({ templateName }: { templateName: string }) => {
  const sx = useSx();

  return (
    <Box sx={sx.root} display="flex" alignItems="center" marginBottom="16px">
      <Title text="Plantillas" />
      <Typography>&quot;{templateName}&quot;</Typography>
    </Box>
  );
};

export default Header;
