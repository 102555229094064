import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useSnackbar from '@core/hooks/useSnackbar';
import useStudyInfo from '@core/hooks/useStudyInfo';
import useWidth from '@core/hooks/useWidth';
import { EmailFormRequest } from '@core/types';
import useShareByEmail from 'app/features/study/hooks/useShareByEmail';
import useUploadReport from 'app/features/study/hooks/useUploadReport';
import Form from './Form';
import { ReportDocument } from './Form/documentTypes';

const Reports = () => {
  const [searchParams] = useSearchParams();
  const studyUid = searchParams.get('studyUid') || '';
  const navigate = useNavigate();
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;
  const { uploadReport, isUploading } = useUploadReport(studyUid);
  const { share } = useShareByEmail(studyUid);
  const { studyInfo } = useStudyInfo({ studyInstanceUidOrHash: studyUid });
  const showSnackbar = useSnackbar();

  const handleShareByEmail = useCallback(async () => {
    if (!studyInfo?.patientsEmail || !studyInfo?.patientsFullName) {
      console.error('Patient email or name is undefined');
      return;
    }

    try {
      const req = {
        body: '',
        email: studyInfo.patientsEmail,
        name: studyInfo.patientsFullName,
      };

      await share(req as EmailFormRequest);
    } catch (error: any) {
      console.error(error);
    }
  }, [studyInfo, share]);

  const handleReportSubmit = async (report: ReportDocument) => {
    if (!studyUid) return;
    try {
      const response = await uploadReport(report);

      if (response.success) {
        await handleShareByEmail();
        window?.opener?.onSuccessResponse?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte creado',
          message: 'El reporte se ha creado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const onCancel = () => {
    window?.opener?.onCancelReport();
  };

  return (
    <Container sx={{ height: '100%', paddingY: '20px' }} maxWidth={deviceWidth} disableGutters>
      <Box
        sx={{ cursor: 'pointer', position: 'fixed' }}
        onClick={() => {
          navigate(`/settings/reports?studyUid=${studyUid}`);
        }}
        display="flex"
        alignItems="center"
      >
        <ArrowBackIosIcon />
        <Typography>Regresar a listado de estudios</Typography>
      </Box>
      <Form
        studyId={studyUid ?? ''}
        handleCancel={onCancel}
        handleReportSubmit={handleReportSubmit}
        isLoading={isUploading}
      />
    </Container>
  );
};

export default Reports;
