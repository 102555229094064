import {
  IEditor,
  EditorPlugin,
  PluginEvent,
  ContentChangedEvent,
  CompatibleContentChangedEvent,
} from 'roosterjs';

const acceptedEvents = {
  ContentChanged: 3,
  Format: 7,
};

const acceptedEventTypes = Object.values(acceptedEvents);

const preserveSelectionEvents = [
  'changeFontSize',
  'toggleListType',
  'setIndentation',
  'setAlignment',
  'alignList',
];
class UpdateStatePlugin implements EditorPlugin {
  private editor: IEditor | null | undefined;

  private onInputChange: (value: string) => void;

  constructor(onInputChange: (value: string) => void) {
    this.onInputChange = onInputChange;
  }

  getName() {
    return 'UpdateStatePlugin';
  }

  initialize(editor: IEditor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  onPluginEvent(event: PluginEvent) {
    if (event.eventType === 10) {
      event.sanitizingOption.additionalAllowedAttributes = ['white-space'];
      event.sanitizingOption.cssStyleCallbacks['background-color'] = () => false;
      event.sanitizingOption.cssStyleCallbacks.color = () => false;
      event.sanitizingOption.cssStyleCallbacks['white-space'] = () => false;
      event.sanitizingOption.cssStyleCallbacks.position = () => true;
      event.sanitizingOption.cssStyleCallbacks.overflow = () => true;
    }

    if (event.eventType === acceptedEvents.Format) {
      const trigerredEvent = event as ContentChangedEvent | CompatibleContentChangedEvent;

      const existingApiName = Boolean(trigerredEvent.additionalData?.formatApiName);
      const nonExistingApiName = 'NULL';
      const shouldClearSelections = !preserveSelectionEvents.includes(
        trigerredEvent.additionalData?.formatApiName || nonExistingApiName,
      );

      if (existingApiName && shouldClearSelections) {
        const existingSelections = window.getSelection();
        existingSelections?.removeAllRanges();
      }
    }

    if (acceptedEventTypes.includes(event.eventType)) {
      this.onInputChange(this.editor?.getContent() || '');
    }
  }

  setContent(value: string) {
    this.editor?.setContent(value);
  }
}

export default UpdateStatePlugin;
