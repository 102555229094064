import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { TemplateFile, TemplateFolder } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import FolderForm from '../../components/FolderForm';
import FOLDER_ERROR_MESSAGES from '../../constants/folderErrorMessages';
import useUpdateTemplate from '../../edit/hooks/useUpdateTemplate';
import useAddFolder from '../../hooks/useAddFolder';
import useEditFolder from '../../hooks/useEditFolder';
import useGetFolders from '../../hooks/useGetFolders';
import FormDialog from './FormDialog';
import { InitialValues } from './FormDialog/FormDialogProps';
import Header from './Header';
import useSx from './sx';
import useAddTemplateState from './useAddTemplateState';

const navigateToFolders = (
  initialFolder: {
    [key: string]: TemplateFile | TemplateFolder;
  },
  folderPath: number[],
) => {
  let FOLDER = initialFolder;
  const folderIds = [...folderPath];
  while (folderIds.length > 0) {
    const folderId = folderIds.shift();
    const foundFolder = FOLDER[`folder-${folderId}`];

    if (foundFolder && foundFolder.subItems) {
      FOLDER = foundFolder.subItems;
    } else {
      break;
    }
  }

  return FOLDER;
};

const getFolderName = (folder: { [key: string]: TemplateFile | TemplateFolder }) => {
  let counter = 1;
  const placeholder = 'Nueva Carpeta';
  let FOLDER_NAME = placeholder;

  const existingFolderNames = Object.values(folder)
    .filter((s) => !s.isFile)
    .map((s) => s.name);

  const uniqueFolderNames = new Set(existingFolderNames);

  while (uniqueFolderNames.has(FOLDER_NAME)) {
    FOLDER_NAME = placeholder + ` ${counter}`;
    counter += 1;
  }

  return FOLDER_NAME;
};

const AddTemplate = () => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { userUid } = useProfile();
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState<InitialValues | null>(null);

  const { addFolderAsync } = useAddFolder();
  const { addTemplate } = useAddTemplateState();
  const { updateTemplate } = useUpdateTemplate();
  const filters = {
    Section: formValues?.organizationWide ? 'Organization' : 'Private',
  };
  const { folders } = useGetFolders(filters, showModal);

  const onSave = async (values: InitialValues) => {
    setFormValues(values);
    setShowModal(true);
  };

  const onAdd = async ({
    folderId,
    templateName,
  }: {
    templateName: string;
    folderPath: number[];
    folderId: number | undefined;
  }) => {
    const addTemplateResponse = await addTemplate({
      ...formValues,
      templateName,
      templateFolderId: folderId,
    });
    if (!addTemplateResponse.success) {
      showSnackbar({
        message: 'La plantilla no ha sido guardada.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }
    showSnackbar({
      message: 'Plantilla configurada con éxito',
      title: 'Plantilla guardada',
      type: 'success',
    });
    navigate(`/templates/${addTemplateResponse.template.templateId}`);
  };

  const handleAddNewFolder = async (folderPath: number[]) => {
    const workingFolder = navigateToFolders(folders?.root || {}, folderPath);

    const folderName = getFolderName(workingFolder);

    //It should be storing according to what is showing
    const isOrganizationWide = filters.Section === 'Organization';

    if (formValues) {
      const response = await addFolderAsync({
        folderName: folderName,
        folderPath,
        isOrganizationWide: isOrganizationWide,
      });
      if (isCustomErroHandlerResponse(response)) {
        showSnackbar({
          type: 'error',
          message: FOLDER_ERROR_MESSAGES[response.statusCode] || FOLDER_ERROR_MESSAGES.default,
          title: 'Error',
        });
      }
    }
  };

  const onOverrideTemplate = async (template: TemplateFile, folderId: number | undefined) => {
    if (formValues) {
      if (template.ownerUserUid !== userUid) {
        showSnackbar({
          type: 'error',
          title: 'Error',
          message:
            'No tienes permiso para modificar a esta plantilla. Por favor, selecciona una que te pertenezca.',
        });
        return;
      }

      const updateTemplateResponse = await updateTemplate({
        content: formValues?.content,
        templateId: template.folderHierarchyItemId,
        templateName: template.name,
        organizationWide: !!template.organizationWide,
        templateFolderId: folderId,
      });

      if (!updateTemplateResponse.success) {
        showSnackbar({
          message: 'La plantilla no ha sido actualizada.',
          title: 'Ocurrió un error',
          type: 'error',
        });
        return;
      }

      showSnackbar({
        message: 'Plantilla se actualizó con éxito',
        title: 'Plantilla actualizada',
        type: 'success',
      });
      navigate(`/templates/${updateTemplateResponse.template.templateId}`);
    }
  };

  const { editFolderAsync } = useEditFolder();

  return (
    <Container sx={sx.root} style={{ maxWidth: '100%' }} disableGutters>
      <Header />
      <ContainerCard>
        <FormDialog
          title="Nueva plantilla"
          canUserEditTemplate
          onSave={onSave}
          initialValues={{
            templateName: '',
            content: '',
            folderName: '',
            organizationWide: false,
          }}
        />
        <FolderForm
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          initialValues={formValues || { templateName: '' }}
          foldersAndTemplates={folders?.root || {}}
          handleAddNewFolder={handleAddNewFolder}
          handleOnSave={onAdd}
          initialFolderPath={[]}
          onTemplateClick={onOverrideTemplate}
          onEditFolder={async (templateFolderName, folder, folderPath) => {
            const response = await editFolderAsync({
              templateFolderId: folder.folderHierarchyItemId,
              templateFolderName,
              isOrganizationWide: filters.Section === 'Organization',
              folderPath,
            });
            if (isCustomErroHandlerResponse(response)) {
              showSnackbar({
                type: 'error',
                title: 'Error',
                message: FOLDER_ERROR_MESSAGES[response.statusCode] || FOLDER_ERROR_MESSAGES.status,
              });
            }
          }}
        />
      </ContainerCard>
    </Container>
  );
};

export default AddTemplate;
